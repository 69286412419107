






































import Vue from 'vue';
import {Backend} from "api-frontend";
import {formatDate, formatTime} from "@/helpers/format-date-time";
import {LocalDate, LocalTime} from "js-joda";
import {MiniSchedule} from "mp-common/src/types/entities/mini-schedule";
import {miniSchedule} from "mp-common/src/types/api/miniScheduleApi";
import {navigate, routes} from "@/router/routes";

export default Vue.extend({
  name: 'SelectCreatedPlan',
  props: {
    user: {},
    backend: {
      type: Backend,
    }
  },
  data() {
    return {
      createdPlans: [] as MiniSchedule[],
      navigate: navigate,
      routes: routes,
    }
  },
  created: function () {
    this.fetchCreatedPlans();
  },
  methods: {
    async fetchCreatedPlans() {
      this.createdPlans = [];
      const result = await this.backend.query(miniSchedule.getAll, undefined);
      if (result) {
        this.createdPlans = result.sort((first, next) => next.created.compareTo(first.created));
      }
    },
    async deletePlan(id: number) {
      const result = await this.backend.query(miniSchedule.delete, {miniScheduleId: id});
      if (result?.success) {
        await this.fetchCreatedPlans()
      }
    },
    formatDate(date: LocalDate) {
      return formatDate(date);
    },
    formatTime(time: LocalTime) {
      return formatTime(time);
    },
  }
})
